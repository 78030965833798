<template>
  <div class="product-list-wrap">
    <div class="search-wrap">
      <div class="status-block">
        <p>商品最後更新時間：{{ getProductStatus.last_update_pos_data_time }}</p>
        <p>商品狀態： {{ getProductStatus.update_status }}</p>
      </div>
      <div class="function-block">
        <el-button @click="updateProduct()" :disabled="getProductStatus.update_status !== '更新完成'">
          更新商品
        </el-button>
        <el-select
          v-model="select_cate"
          placeholder="請選擇類別"
          style="width: 140px"
          clearable
          filterable
          @change="handleSelectCategory($event)"
        >
          <el-option label="全部類別" value=""></el-option>
          <el-option v-for="item in getCategoryList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-input
          v-model="search_text"
          class="search-bar"
          placeholder="請輸入商品ID/品名"
          :prefix-icon="Search"
          clearable
          @keyup.enter="handleSearch"
          @clear="handleSearch"
        />
      </div>
    </div>

    <el-table
      ref="listTable"
      :data="getProductList"
      height="94%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="category" label="類別" width="120" />
      <el-table-column prop="alias_code" label="商品ID" width="150" />
      <el-table-column prop="product_name" label="商品名稱" />
      <el-table-column prop="" label="預設狀態" width="120">
        <template #default="scope">
          <span>{{ scope.row.default_option_status ? '已預設' : '未設定' }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="right" width="370">
        <template #default="scope">
          <el-button
            type="info"
            plain
            style="font-weight: normal; border: none; width: 110px"
            @click="handleClickDetail(scope.$index, scope.row)"
          >
            詳細
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
import { computed, ref, watch, getCurrentInstance, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Search } from '@element-plus/icons-vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'product-list',
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { getSearchText, getCategoryId, getProductList, getPageSize, getPageTotal, getIsDetail, getProductStatus } =
      useGetters('default', [
        'getSearchText',
        'getCategoryId',
        'getProductList',
        'getPageSize',
        'getPageTotal',
        'getIsDetail',
        'getProductStatus',
      ]);
    //取得選項
    const getCategoryList = useGetters('common', ['getCategoryList']);
    const { SET_IS_DETAIL, SET_SEARCH, SET_CATEGORY_ID } = useMutations('default', [
      'SET_IS_DETAIL',
      'SET_SEARCH',
      'SET_CATEGORY_ID',
    ]);
    const { doGetAllProductList, doGetAllProductSummary, doUpdateProductInfo } = useActions('default', [
      'doGetAllProductList',
      'doGetAllProductSummary',
      'doUpdateProductInfo',
    ]);
    //初始化
    const search_text = ref(getSearchText.value);
    const select_cate = ref(getCategoryId.value);
    const search_data = computed(() => {
      return {
        product_name: search_text.value,
        category_id: select_cate.value,
      };
    });
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: route.query.page ? route.query.page : 1 };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      if (route.path === '/product/default' && !getIsDetail.value) {
        await doGetAllProductList({
          start: (currentPage.value - 1) * getPageSize.value,
          ...search_data.value,
        });
        await doGetAllProductSummary(search_data.value);
        await doUpdateProductInfo();
      } else {
        await doGetAllProductList({
          start: (currentPage.value - 1) * getPageSize.value,
          ...search_data.value,
        });
        SET_IS_DETAIL(false);
      }
    });
    //搜尋
    const handleSearch = async () => {
      SET_SEARCH(search_text.value);
      currentPage.value = 1;
      const query = { page: currentPage.value };
      router.push({ query });
      await doGetAllProductList({
        start: (currentPage.value - 1) * getPageSize.value,
        ...search_data.value,
      });
      await doGetAllProductSummary(search_data.value);
    };
    //選擇類別
    const handleSelectCategory = async (cate) => {
      SET_CATEGORY_ID(cate);
      currentPage.value = 1;
      const query = { page: currentPage.value };
      router.push({ query });
      await doGetAllProductList({
        start: (currentPage.value - 1) * getPageSize.value,
        ...search_data.value,
      });
      await doGetAllProductSummary(search_data.value);
    };
    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page) {
          currentPage.value = Number(route.query.page);
          doGetAllProductList({
            start: (currentPage.value - 1) * getPageSize.value,
            ...search_data.value,
          });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );
    //點擊詳細
    const doGetAllProductDetail = useActions('default', ['doGetAllProductDetail']);
    const handleClickDetail = async (index, item) => {
      await doGetAllProductDetail(item);
      router.push(route.path + '/detail');
    };
    //更新商品
    const doUpdateProduct = useActions('default', ['doUpdateProduct']);
    const updateProduct = async () => {
      try {
        await doUpdateProduct();
        proxy.$message({
          type: 'success',
          message: `新增成功`,
        });
        await doUpdateProductInfo();
      } catch (e) {
        e;
      }
    };
    let timer;
    watch(
      () => getProductStatus.value.update_status,
      (newVal) => {
        if (newVal === '更新商品資料中') {
          timer = setInterval(() => {
            doUpdateProductInfo();
          }, 30 * 1000);
        } else {
          clearInterval(timer);
        }
      },
      {
        immediate: true,
      }
    );
    // 在组件卸載時清除定時器
    onUnmounted(() => {
      clearInterval(timer);
    });

    return {
      Search,
      search_text,
      select_cate,
      listTable,
      currentPage,
      getProductList,
      getPageSize,
      getPageTotal,
      getCategoryList,
      handleSearch,
      handleSelectCategory,
      handleCurrentChange,
      handleClickDetail,
      updateProduct,
      getProductStatus,
      timer,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 51px;
.product-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.search-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
}
.status-block {
  display: flex;
  p {
    font-size: 14px;
  }
  p:first-child {
    margin-right: 15px;
  }
}
.function-block {
  display: flex;
  width: 42%;
  align-items: center;
  justify-content: space-between;
}
.search-bar {
  width: 200px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translate(-50%, 50%);
}
:deep(.el-table__body-wrapper) {
  height: 550px !important;
}
</style>
